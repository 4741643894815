<template>
  <div>
    <Modal :options="{width: '40vw', type: 'danger'}" @close="$emit('close')">
      <div class="title">Eliminar catálogo</div>
      <div class="body">
        <p class="text-center">Estas por eliminar el catálogo <strong>{{ catalogo.nombre }}</strong>, esta acción no se puede deshacer.<br/>¿Deseas continuar?</p>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="eliminar_catalogo">Borrar</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import apiCatalogo from '@/apps/formalizacion/api/v2/catalogos'

  export default {
    components: {
      Modal
    }
    ,props: {
      catalogo: {
        type: Object
        ,default() {
          return {
            id: null
            ,nombre: null
          }
        }
      }
    }
    ,methods: {
      async eliminar_catalogo() {
        try {
          let res = (await apiCatalogo.eliminar_catalogo(this.catalogo.id)).data;
          this.$log.info('res', res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>